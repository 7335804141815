import Swiper from "swiper";
import { Navigation} from 'swiper/modules';
import {hoverItemImage} from "./partials/hoverItemImage";


document.addEventListener('mouseup', () => {
    setTimeout(function () {
        for(let img of document.querySelectorAll(".itemlistItem__image img")){
            hoverItemImage(img);
        }
    },200);
});
window.addEventListener("load", () => {
    let itemlistImages = document.querySelectorAll(".itemlistItem__image img");
    for(let img of itemlistImages){
        setTimeout(function () {
            hoverItemImage(img);
        },200);
    }
    for(let itemlist of document.querySelectorAll(".itemList.swiper")){
        const swiper = new Swiper(itemlist, {
            modules: [Navigation],
            slidesPerView: 4,
            spaceBetween: 24,
            navigation: {
                nextEl: itemlist.querySelector('.swiper-button-next'),
                prevEl: itemlist.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.8,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 24
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 24
                }
            }
        });
    }

});

