
export function hoverItemImage(img){
    if(img){
        let hoverSrc = img.dataset.hoverSrc;
        let baseSrc = img.src;
        if(hoverSrc !== '' && hoverSrc !== baseSrc){
            img.addEventListener("mouseover",function (){
                img.src = hoverSrc;
            });
            img.addEventListener("mouseout",function (){
                img.src = baseSrc;
            });
        }
    }
}